import {
  amber, grey, indigo, red, common,
} from '@mui/material/colors';

const colors = {
  white: common.white,
  background: grey[50],
  primary: '#2e012e',
  secondary: '#2e012e',
  positive: '#2e012e',
  medium: amber[700],
  negative: red[500],
  neutral: grey[500],
  geometry: '#800080',
  t: indigo[900],
};

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  colors,
};
